import {AuthContextInterface} from "../../context/auth/AuthContextInterface";
import {AccessTime, AccountTree, CurrencyBitcoin, SleddingOutlined} from "@mui/icons-material";

/**
 * Liste des pages à inclure dans le menu de gauche et haut
 */
interface pageLink {
    id: number;
    title: string;
    path: string;
    icon: any;
    role?: string;
}

export const pagesLinks: pageLink[] = [
    {id: 0, title: 'Congés', path: '/conges', icon: SleddingOutlined},
    {id: 1, title: 'Temps', path: '/timetracking/all', icon: AccessTime, role: 'ROLE_ADMIN_RH'},
    {id: 2, title: 'Projets', path: '/suiviprojets', icon: AccountTree, role: 'ROLE_ADMIN_PROJETS'},
    {id: 3, title: 'Utilisateurs', path: '/users', icon: AccountTree, role: 'ROLE_ADMIN_USER'},
    {id: 4, title: 'Bitrise', path: '/bitrise', icon: CurrencyBitcoin}
];

/**
 * Liste des liens dans le menu utilisateur
 */
interface UserMenuLink {
    title: string;
    path: string;
}

export const userMenuLinks: UserMenuLink[] = [
    {title: 'Deconnexion', path: '/logout'},
];

/** Texte du footer, si laissé à null on aura pas de footer */
export const footerText = 'Copyright © 2023 MLD'; //si null, pas de footer

/**
 * Titre du site, affiché à gauche du header
 * à faire évoluer quand on voudra un logo
 */
export const titreMenuHaut = 'Intranet MLD';

/**
 * Fonction de renvoi du nom de l'utilisateur connecté
 * à modifier en fonction de la structure des objet User contenus dans AuthContextInterface
 * @param authContext
 */
export const getConnectedserName = (authContext: AuthContextInterface): string => {
    return authContext.user?.prenom + ' ' + authContext.user?.nom
}